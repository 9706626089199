import _ from 'loadsh';
import contentfulExtension from 'contentful-ui-extensions-sdk/dist/cf-extension-api.js';

/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';

/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/tinymce-5/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';

/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* import './plugins/powerpaste/plugin'; */
/* import './plugins/powerpaste/js/wordimport'; */

/* content UI CSS is required */
// import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.css';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
// import contentCss from 'tinymce/skins/content/default/content.css';

window.contentfulExtension = contentfulExtension;

window.contentfulExtension.init(function (api) {
  const fieldType = api.field.type; 
  const isObject = fieldType === 'Object';

  function tinymceForContentful(api) {
    api.window.startAutoResizer();

    tinymce.init({
      selector: '#editor',
      plugins: 'advlist image code emoticons link lists table ',
      toolbar: 'customInsertButton | bold image code italic | bullist numlist | link emoticons',
      min_height: 600,
      max_height: 750,
      autoresize_bottom_margin: 15,
      resize: false,
      image_caption: true,
      extended_valid_elements: "*[*]",
      init_instance_callback: function (editor) {
        let listening = true;

        const getEditorContent = () => editor.getContent() || '';
        const getApiContent = () => isObject ? (api.field.getValue()?.content || '') : (api.field.getValue() || '')
        const setContent = content => {
          const apiContent = isObject ? content?.content || '' : (content || '');
          const editorContent = getEditorContent();
          if (apiContent !== editorContent) {
            editor.setContent(apiContent);
          }
        }

        setContent(api.field.getValue());
        api.field.onValueChanged(value => {
          if (listening) {
            setContent(value);
          }
        })

        const onEditorChange = () => {
          const editorContent = getEditorContent();
          const apiContent = getApiContent();

          if (editorContent !== apiContent) {
            listening = false;
            api.field.setValue(isObject ? {content: editorContent } : editorContent).then(function () {
              listening = true;
            }).catch(function (err) {
              console.log("Error setting content", err);
              listening = true;
            });
          }          
        }

        const throttled = _.throttle(onEditorChange, 500, { leading: true });
        editor.on('change keyup setcontent blur', throttled);
      },
      setup: function(editor) {
        editor.ui.registry.addButton('customInsertButton', {
          text: 'Footnote',
          icon: 'superscript',
          onAction: function (_) {
            editor.insertContent('<a class="footnote" href="#sources">i</a>');
          }
        });
      }
    });
  }

  tinymceForContentful(api);
});
